import { FC } from 'react';

import { ProductsCarouselTitle } from '@/core/ui/components/ProductsCarousel/components/ProductsCarouselTitle';
import { ProductsCarousel } from '@/core/ui/components/ProductsCarousel/ProductsCarousel';
import { SanityTitleFormatter } from '@/core/ui/components/SanityTitleFormatter/SanityTitleFormatter';
import { cn } from '@/core/ui/utils';
import { isTruthy } from '@lib/api/is-truthy';

import { ProductsSectionData } from '../../queries/types';

export const ProductsSectionContent: FC<{
  section: ProductsSectionData;
}> = ({ section }) => {
  const products = section.products.filter(isTruthy);

  return (
    <ProductsCarousel
      className={cn('px-4', 'xl:px-0')}
      products={products}
      title={
        <ProductsCarouselTitle
          title={<SanityTitleFormatter>{section.title}</SanityTitleFormatter>}
        />
      }
    />
  );
};
