import { pushGtmEvent } from '@/core/services/pushGtmEvent';

type SendBannerSelectEventArgs = {
  position: number;
  type: string | undefined;
  backgroundImage: string;
  link?: string | null;
  title?: string;
};
export const sendBannerSelectEvent = ({
  backgroundImage,
  title,
  position,
  link,
  type,
}: SendBannerSelectEventArgs) =>
  pushGtmEvent({
    event: 'banner_select',
    lite_position: position,
    lite_type: mapTypenameToType(type),
    lite_link: link ?? null,
    lite_background_image: backgroundImage,
    lite_title: title,
  });

const mapTypenameToType = (typename: string | undefined) => {
  if (typename === 'WebLinkBanner') return 'link';
  if (typename === 'WebStaticBanner') return 'static';

  return typename;
};
