import { KeenSliderOptions } from 'keen-slider/react';
import { ReactNode } from 'react';

import { useSlider } from '@/core/hooks/useSlider';
import { BannersDots } from '@/core/ui/components/BannersDots/BannersDots';
import { ProductsCarouselHeader } from '@/core/ui/components/ProductsCarousel/components/ProductsCarouselHeader';
import { ProductsCarouselSlider } from '@/core/ui/components/ProductsCarousel/components/ProductsCarouselSlider';
import { cn } from '@/core/ui/utils';
import { Product } from '@/modules/product/queries/types';

type ProductsCarouselProps = {
  title: ReactNode;
  products: readonly Product[];
  className?: string;
  customSlides?: ReactNode[];
  breakpoints?: KeenSliderOptions['breakpoints'];
  slides?: KeenSliderOptions['slides'];
  isProductTitleNotClickable?: boolean;
};

export const ProductsCarousel = ({
  title,
  products,
  className,
  customSlides,
  breakpoints,
  slides,
  isProductTitleNotClickable,
}: ProductsCarouselProps) => {
  const breakpointsConfig = breakpoints || {
    '(min-width: 768px)': { slides: { perView: 3, spacing: 24 } },
    '(min-width: 1280px)': { slides: { perView: 5, spacing: 24 } },
  };
  const slidesConfig = slides || { perView: 2, spacing: 16 };

  const {
    sliderRef,
    handleNext,
    handlePrev,
    isNextDisabled,
    isPrevDisabled,
    handleDotClick,
    dotsLength,
    showControls,
    currentSlide,
  } = useSlider({
    breakpoints: breakpointsConfig,
    slides: slidesConfig,
  });

  return (
    <div className={cn(className)}>
      <ProductsCarouselHeader
        title={title}
        onNext={handleNext}
        onPrev={handlePrev}
        isNextDisabled={isNextDisabled}
        isPrevDisabled={isPrevDisabled}
      />
      <div ref={sliderRef} className="keen-slider list-none">
        <ProductsCarouselSlider
          products={products}
          customSlides={customSlides}
          isProductTitleNotClickable={isProductTitleNotClickable}
        />
      </div>
      {showControls && !!dotsLength && (
        <BannersDots
          currentSlide={currentSlide}
          totalSlides={dotsLength}
          onClick={handleDotClick}
        />
      )}
    </div>
  );
};
