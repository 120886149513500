import { useTranslation } from 'next-i18next';
import { MouseEvent, ReactNode } from 'react';

import { cn } from '@/core/ui/utils';
import { AccessibleSvg } from '@lib/theme/components/AccessibleSvg';
import { buttonIconComponents } from '@lib/theme/config/buttonIcons';

type ProductCarouselHeaderProps = {
  title: ReactNode;
  onNext: (e: MouseEvent<HTMLButtonElement>) => void;
  onPrev: (e: MouseEvent<HTMLButtonElement>) => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
};

const buttonClasses = [
  'w-10',
  'h-10',
  'rounded-full',
  'text-primary-500',
  'bg-primary-100',
  'focus-visible:ring-primary-200',
  'hover:bg-primary-200',
  'enabled:active:bg-primary-700',
  'disabled:bg-primary-050',
  'disabled:text-primary-200',
];
const iconsClasses = ['w-6', 'h-6', 'inline-flex'];
const containerClasses = ['flex', 'flex-col', 'items-center'];

export const ProductsCarouselHeader = ({
  title,
  isPrevDisabled,
  onNext,
  isNextDisabled,
  onPrev,
}: ProductCarouselHeaderProps) => {
  const { t } = useTranslation('common');

  return (
    <div
      className={cn(
        'mb-4',
        'md:flex',
        'md:items-center',
        'md:mb-8',
        'md:justify-between'
      )}
    >
      <div>{title}</div>
      <div className="hidden md:flex md:items-center">
        <button
          type="button"
          className={cn(buttonClasses)}
          disabled={isPrevDisabled}
          onClick={onPrev}
        >
          <div className={cn(containerClasses)}>
            <AccessibleSvg
              SvgComponent={buttonIconComponents.ChevronLeftSmIcon}
              title={t('Previous slide')}
              className={cn(iconsClasses)}
            />
          </div>
        </button>
        <button
          type="button"
          className={cn(buttonClasses, 'ml-3', 'lg:ml-2')}
          disabled={isNextDisabled}
          onClick={onNext}
        >
          <div className={cn(containerClasses)}>
            <AccessibleSvg
              SvgComponent={buttonIconComponents.ChevronRightSmIcon}
              title={t('Next slide')}
              className={cn(iconsClasses)}
            />
          </div>
        </button>
      </div>
    </div>
  );
};
